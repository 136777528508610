@import "../../styles/abstracts/variables";

.fullpage-preview {
  position: relative;
  background: transparent !important;

  &__close {
    position: fixed !important;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
  }

  & > .fullpage-content {
    max-width: 95%;
    max-height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $brandWhite;
    border-radius: 0 !important;
  }
}
