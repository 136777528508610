// styles defining some standard styles for commonly used HTML elements

body {
  background-color: $secondaryColor;
  color: $brandBlack;
  font-family: "Henderson BCG Sans";

  * {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Henderson BCG Sans";
}

p {
  @include font-size(16, 22);
  font-weight: 400;
}

a {
  cursor: pointer;
  color: $primaryColor;
  &:hover,
  &:active,
  &:focus {
    color: $bcg-green--dark;
  }
}

.error-message {
  color: $notify-warn-highlight;
  @include font-size(14);
  &::before {
    @include warning-icon;
    @include font-size(16);
  }
}

.custom-tooltip {
  content: "?";
  color: $brandWhite;
  background-color: $gray--darkest;
  padding: 1px 5px;
  border-radius: 50%;
  margin: 0px 5px;
  @include font-size(12, 14);
  vertical-align: middle;
}

.pile-count {
  background-color: $gray--darkest;
  color: $brandWhite;
  @include font-size(14, 14);
  margin-left: 8px;
  padding: 4px 6px;
  @include rounded;

  &--active {
    background-color: $primaryColor;
  }
}

.overlay-loader,
.full-width-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #00000090;
  @include flex-center;

  > img {
    height: 32px;
    width: 32px;
    animation: rotate-loader 2s linear infinite;
  }
}

.full-width-overlay-white {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #ffffff90;
  > img {
    height: 32px;
    width: 32px;
    animation: rotate-loader 2s linear infinite;
  }
}
