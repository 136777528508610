@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.slider-container {
  margin: 0px 48px;
  margin-top: -8px;
  height: $carouselHeight;
  min-height: 250px;
  overflow: hidden;

  .slide-progress {
    color: $brandBlack;
    height: 18px;
    width: 100%;

    &__slide-no {
      color: $brandWhite;
      background-color: $primaryColor;
      padding: 2px 6px;
      margin-right: 4px;

      &.double-digit {
        left: -22px;
      }

      &.triple-digit {
        left: -30px;
      }
    }

    &__slide-status {
      display: inline;
      margin-right: 10px;

      &--title {
        padding-right: 4px;
        font-weight: bold;
      }
    }

    &__action-btn {
      float: right;
      color: $primaryColor;
      cursor: pointer;
    }
  }

  .custom-arrow {
    &--next {
      top: 50%;
      right: 0;
      &::before {
        content: " ";
      }
    }

    &--prev {
      left: 0;
      top: 50%;
      z-index: 1;
      &::before {
        content: " ";
      }
    }
  }

  .slick-list {
    padding: 4px !important;
  }
}

.single-slide-mode {
  margin: 0px 48px;
  margin-top: -8px;
  position: relative;

  .extra-slide {
    display: none;
  }
}

.single-slide-mode .slider-block,
.single-slide-view {
  outline: none;
  position: relative;
  height: calc(#{$sliderImageHeight} - 20px);
  min-height: 200px;
  width: 74vh !important;
  top: 10px;
  left: 50%;
  transform: translateX(-52%);

  img {
    margin: auto;
    height: calc(100% - 30px);
    min-height: 170px; // container (200px) minus footer
    max-width: 100%;
    // object-fit: scale-down;
    box-shadow: 0 0 5px 0 $gray;
  }

  .slide-progress {
    position: relative;
    bottom: 0px;
  }
}

.multiple-slide-mode {
  .slider-block {
    outline: none;
    position: relative;
    height: $sliderImageHeight;
    min-height: 200px;
    width: 80vh !important;

    img {
      margin: auto;
      height: calc(100% - 30px);
      min-height: 170px; // container (200px) minus footer
      max-width: 100%;
      // object-fit: scale-down;
      box-shadow: 0 0 5px 0 $gray;
    }

    &.wide-16-9 {
      img {
        position: relative;
        top: 50%;
        transform: translateY(-54%);
      }
    }
  }

  .slider-block.standard-4-3 {
    width: 60vh !important;
    img {
      margin-top: 10px;
    }
  }

  .slick-slide {
    transition: 0.75s;
    @include flex-center;

    &:not(.slick-center) {
      transform: scale(0.5);

      .slider-block {
        .slide-progress__slide-status,
        .slide-progress__action-btn {
          display: none;
        }
      }

      .slider-container__status-checkbox {
        line-height: 0.6;
      }
    }

    &.slick-center {
      transform: scale(1);
      position: relative;
      z-index: 1;
    }
  }
}
