.table-container {
  width: 100%;
  padding: 6px 12px;

  &__list {
    margin-top: 8px;
    height: 100%;
    width: 100%;
  }

  &.placeholder {
    display: flex;
    width: 100%;
    .ui.placeholder {
      padding: 12px;
      width: 100%;
      max-width: 100%;
    }
  }
}

.table-list {
  .image-list__item {
    min-width: 220px;
    min-height: 100px;
    width: 100%;
    height: initial;
    max-height: 140px;
    max-width: 400px;
    margin: 0.75rem;
  }
}
