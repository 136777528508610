@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.file-list-loader {
  .ui.placeholder {
    margin-bottom: 16px;
    @include rounded;
  }

  .ui.placeholder + .ui.placeholder {
    margin-top: 0;
  }

  &__card {
    max-width: 100% !important;
    height: 112px !important;
  }
}
