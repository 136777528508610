@include spacing;

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

// ------------ DISPLAY  ---------- //

.d-none {
  display: none !important;
}

.d-flex-center {
  @include flex-center;
}

.d-inline {
  display: inline !important;
}

.visibility-show {
  opacity: 1;
}

.visibility-hide {
  opacity: 0 !important;
}

// ------------ TYPOGRAPHY  ---------- //
.text-green {
  color: $bcg-green !important;
}

.text-green-light {
  color: $primaryColor !important;
}

.text-gray {
  color: $gray--dark !important;
}

.text-red {
  color: $bcg-red !important;
}

.text-warning {
  color: orange !important;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.word-break {
  word-break: break-word;
}

// ------------ CURSOR  ---------- //
.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

// ------------ GENERIC ----------- //

.ui.fullscreen.modal {
  width: 100% !important;
  height: 100vh !important;
  margin: 0em !important;
  top: 0;
  border-radius: 0;
}

.loader-with-animation {
  animation: rotate-loader 2s linear infinite;
}

.rounded {
  @include rounded;
}

.box-shadow {
  @include hover-shadow;
}
