.auto-sanitization-confirm {
  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;
    }
    margin-bottom: 24px;
  }

  &__action-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      width: 136px;
      height: 40px;
    }
  }

  .not-allowed {
    cursor: not-allowed;
  }
}
