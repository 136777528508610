@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.image-container {
  width: 100%;
  padding: 6px 12px;

  &__header {
    padding: 0px 12px;
    width: 100%;
    height: 32px;
    @include flex-center-vert;
    justify-content: flex-start;
    color: $primaryColor;
    font-weight: bold;

    &--checkboxes {
      width: 100%;
    }
  }

  &--select {
    color: $brandBlack;
    padding-right: 32px;

    &.disabled {
      cursor: default;
      color: $gray;
    }

    input + label {
      margin-left: -16px !important;
    }

    input + label:before,
    input + label:after {
      transform: scale(0.7) !important;
      margin-left: 10px !important;
    }
  }

  &__list {
    height: calc(100% - 32px);
    width: 100%;

    &--no-header {
      height: 100%;
      margin-top: 8px;
    }
  }

  &.placeholder {
    display: flex;
    width: 100%;
    .ui.placeholder {
      padding: 12px;
      width: 100%;
      max-width: 100%;
    }
  }

  &.no-result-container {
    .simplebar-content {
      height: 100%;
      .no-result {
        height: 100%;
      }
    }
  }
}
