@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.delete-confirmation {
  width: 576px !important;

  &__header-container {
    @include flex-center-vert;
    justify-content: space-between;

    h3 {
      @include flex-center;
      margin: 0 !important;
    }
  }

  &__description {
    margin-top: 16px;
    margin-left: 24px;
  }

  &__action-container {
    @include flex-center-vert;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      width: 144px;
      height: 40px;
    }
  }

  // Modal Background Color (overriding background color)
  .content {
    background-color: $warningYellow !important;
  }
}
