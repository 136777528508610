@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.page-header {
  position: relative;
  top: 0;

  &__section-left {
    position: absolute;
    left: 0;
    width: 25vw;
    min-width: 350px;
    height: $pageHeader;
    color: $brandBlack;
    @include flex-center-vert;

    @include respond-to(sm) {
      width: $pageHeader;
    }
  }

  &__brand {
    &-text {
      img {
        height: 54px;
        margin-top: 14px;
      }
      @include respond-to(sm) {
        display: none;
      }
    }
    &-beta {
      font-style: italic;
      font-weight: bold;
      margin-left: 4px;
      color: #177b57;
    }
  }
  &__logo {
    height: $pageHeader;
    width: $pageHeader;
    display: inline-flex;
    background-color: $bcg-green;
    padding: 8px;
    &:hover {
      background-color: $bcg-green--dark;
    }
  }

  &__section-right {
    position: absolute;
    top: 0;
    right: 0;
    height: $pageHeader;
    width: 72px;
    @include flex-center;
  }

  &__brand-text {
    @include font-size(20, 24);
    padding-left: 1.25rem;
    font-weight: bold;
  }
}
