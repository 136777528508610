@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.user-guide-header {
  height: $pageHeader;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;

  &__logo-container {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: $pageHeader;
    width: $pageHeader;
    display: inline-flex;
    background-color: $bcg-green;
    padding: 8px;
    &:hover {
      background-color: $bcg-green--dark;
    }
  }

  &__brand-text {
    @include font-size(20, 24);
    padding-left: 1.25rem;
    font-weight: bold;
  }

  &__close-icon {
    cursor: pointer;
    padding-right: 24px;
    display: flex;

    img {
      margin-left: 40px;
      width: 1.25rem;
    }
  }
}
