@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.preview-slide {
  background-color: $secondaryColor !important;

  &__header {
    color: $primaryColor !important;
    width: 100% !important;
  }

  &__thumbnails {
    @include flex-center;
    height: 85vh;

    img {
      box-shadow: 0 0 5px 0 $gray;
      margin-top: 24px;
    }
  }

  &__single-container {
    position: relative;
    margin-top: 20px;
    span {
      position: absolute;
      top: 0;
    }

    img {
      max-width: 65vw !important;
    }
  }

  &__double-container {
    position: relative;
    margin: 8px;

    span {
      position: absolute;
      top: 0;
    }
  }

  &__loader {
    margin-top: 10vh;
    @include flex-center;
    .ui.placeholder {
      height: 70vh !important;
      width: 70vw !important;
      max-width: 1000px;
    }
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }
}

.double-column {
  flex-direction: column;

  img {
    height: 35vh;
  }
}
