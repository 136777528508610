@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.text-inline-manual-editor {
  padding-right: 8px;

  &__editor {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--textarea {
      width: 100%;
      height: calc(100% - 38px);
      border: 1px solid $gray;
      resize: none;
      padding: 4px;
      border-radius: 3px;
      outline: none;
    }
  }

  &__footer {
    width: 100%;
    height: 34px;
    display: inline-flex;
    justify-content: flex-end;

    &--limit {
      color: $gray;
    }
  }
}
