//  Font Face

@include font-face(
  "Henderson BCG Sans",
  "../../assets/fonts/",
  "HendersonBCGSans-Regular",
  normal,
  normal
);
@include font-face(
  "Henderson BCG Sans",
  "../../assets/fonts/",
  "HendersonBCGSans-Thin",
  100,
  normal
);
@include font-face(
  "Henderson BCG Sans",
  "../../assets/fonts/",
  "HendersonBCGSans-Bold",
  bold,
  normal
);
@include font-face(
  "Henderson BCG Sans",
  "../../assets/fonts/",
  "HendersonBCGSans-Light",
  300,
  normal
);
@include font-face(
  "Henderson BCG Serif",
  "../../assets/fonts/",
  "HendersonBCGSerif-Regular",
  normal,
  normal
);
@include font-face(
  "Henderson BCG Serif",
  "../../assets/fonts/",
  "HendersonBCGSerif-Bold",
  bold,
  normal
);
