@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.suggestion-container {
  width: 100%;
  height: $suggestionContainerHeight;
  overflow-y: auto;
  padding: 8px 12px 0px;
  @include flex-column;
  justify-content: flex-start;

  &.reset-message {
    justify-content: center;
  }

  .ui.placeholder {
    height: 32px;
    width: 100%;
    margin-top: 4px !important;
  }

  &__system-suggestions {
    position: relative;
    height: 60%;
    width: 100%;
    overflow-y: auto;
    margin: 0;
    list-style: none;
    padding: 0;
    @include flex-center-column;

    li {
      cursor: pointer;
      @include flex-center;
      width: 100%;
      margin-bottom: 4px;
      height: 32px;
      min-height: 32px;
      border: 2px solid $gray;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-radius: 5px;

      &:last-child {
        margin-bottom: 0px;
      }

      &:hover,
      &.selected {
        border-color: $bcg-green--dark;
      }

      &:hover:not(.selected) {
        color: $primaryColor;
      }

      &.selected {
        background-color: $bcg-green--lighter;
        .select-icon {
          display: inline;
        }
      }

      .select-icon {
        display: none;
        position: absolute;
        right: 2px;
        padding: 0.3rem 0.4rem;
        height: inherit;
        background-color: inherit;
        border-top: inherit;
        border-bottom: inherit;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &--suggestion {
      width: 95%;
      @include truncate-ellipsis;
      text-align: center;
    }
  }

  &__custom-input-header {
    @include flex-center;
    justify-content: space-between;
    @include font-size(16, 12);
  }

  &__custom-input {
    position: relative;
    padding-bottom: 4px;
    width: 100%;
    max-height: 24px;

    .ui.input > input {
      max-height: 32px;
      padding: 6px 58px 6px 6px !important;
      @include font-size(15, 15);
      border-radius: 5px;

      @include respond-to(sm) {
        @include font-size(14, 14);
      }

      @include respond-to(lg) {
        @include font-size(16, 16);
      }
    }

    &.has-error {
      .ui.input > input {
        border-color: $notify-warn-highlight !important;
      }
    }

    .custom-text-icon {
      display: inline;
      position: absolute;
      right: 4px;
      top: 2px;
      width: 20px;
      height: 20px;
      margin: 4px 4px;
      cursor: pointer;
      opacity: 1;

      &--editing {
        opacity: 1;
      }

      &--not-valid {
        opacity: 0.4;
        cursor: not-allowed;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $brandWhite; // TODO: wait for the new icon and update to $bcg-green--dark
      }
    }

    .select-icon,
    .custom-edit-icon {
      display: none;
      position: absolute;
      right: 8px;
      top: 6px;
    }

    .custom-edit-icon {
      right: 32px;
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

    &.has-custom-text {
      .ui.input > input {
        background-color: $bcg-green--lighter !important;
        border-color: $bcg-green--dark !important;
      }
      &:hover {
        .custom-edit-icon {
          display: inline;
        }
      }

      .custom-text-icon {
        display: none;
        opacity: 0.4;
      }

      .select-icon {
        display: inline;
        opacity: 1;
      }
    }
  }
}
