@import "../../styles/abstracts/variables";

.suggestion-footer {
  height: $suggestionFooterHeight;
  display: block;

  &__reset-button {
    &:hover:not(.disabled) {
      filter: brightness(70%);
    }

    &.disabled {
      filter: brightness(0.8) opacity(0.5);
      color: $gray;
      cursor: default;
    }
  }

  &__icon {
    padding-bottom: 1px;
    width: 12px;
    height: 14px;
  }

  &.placeholder {
    height: 18px;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
