@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.disclaimer-footer {
  position: relative;
  @include font-size(14, 14);
  line-height: 20px;
  color: $gray--darker;
  @include flex-center;
  width: 100%;
}
