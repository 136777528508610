@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.error {
  background: $brandWhite;
  text-align: center;

  &__content-wrapper {
    height: 100vh;
    @include flex-center;
  }

  &__code {
    @include font-size(150, 160);
    font-weight: bold;
    margin: 0;
    color: $gray--light;
    height: auto;
  }

  .error__title {
    @include font-size(48, 50);
    font-weight: bold;
    text-transform: uppercase;
    color: $black--secondary;
    margin: 25px auto;
  }

  .error__message {
    @include font-size(20, 30);
    text-align: center;
    text-transform: uppercase;
    white-space: pre-wrap;
    margin: 16px;
    color: $black--secondary;
  }
}
