@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.image-suggestion-container {
  width: 100%;
  height: $suggestionContainerHeight;
  margin: 8px 0px;
  padding: 0px 4px;
  list-style: none;
  @include flex-center;

  &__option-item {
    position: relative;
    @include flex-center-column;
    width: 32%;
    height: 100%;
    margin: 4px;
    border: 1px solid $gray;
    cursor: pointer;
    @include rounded;

    &:hover:not(.placeholder),
    &.selected:not(.placeholder) {
      @include hover-shadow;
    }

    &:hover:not(.selected) {
      color: $primaryColor;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $gray--light;
      &:hover {
        box-shadow: none;
        color: inherit;
      }
    }

    &.selected {
      background-color: $bcg-green--lighter;
      .select-icon {
        display: inline;
      }
    }

    .select-icon {
      display: none;
      position: absolute;
      right: 8px;
      top: 8px;
    }

    &.placeholder {
      margin-top: initial !important;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin-bottom: 6px;
  }
}
