@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.text-inline-edit-preview {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: $brandWhite;
  word-break: break-word;

  &__deleted-message {
    @include flex-center;
    color: $gray--dark;
    height: 100%;
  }

  &__hover-edit-icon.icon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    background-color: $brandWhite;
    @include font-size(16, 16);
    color: $primaryColor;
    cursor: pointer;
    opacity: 0;

    &:hover {
      opacity: 1 !important;
    }
  }

  &:hover &__hover-edit-icon.icon {
    opacity: 0.8;
  }

  .grouped-paragraph {
    padding-right: 26px;
    cursor: text;
    width: 100%;
  }
}
