@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.text-list {
  display: flex;

  .simplebar-content:after,
  .simplebar-content:before {
    content: none !important;
  }

  &__all-texts {
    padding: 8px;
    padding-right: 0px;
    width: 42%;
    height: 100%;
    border-right: 2px solid $gray--light;

    .ui.placeholder {
      margin: 10px;
      height: calc(100% - 20px);
    }

    .ui.placeholder {
      height: 32px;
      margin: 4px 12px 0px 0px !important;
    }
  }

  &__list-wrapper {
    overflow-y: auto;
    height: 100%;
    max-height: 100%;

    .ui.list {
      overflow-y: auto;
      margin-right: 12px;

      .item {
        margin: 0px 4px 6px;
        padding: 4px 6px;
        text-overflow: ellipsis;
        text-align: left;
        overflow: hidden;
        height: 28px;
        white-space: nowrap;
        background-color: $gray--light;
        color: $gray--darker;
        @include font-size(14, 20);
        border: 2px solid $gray--light;
        cursor: pointer;
        border-radius: 5px;

        &:hover:not(.active) {
          color: $primaryColor;
          @include hover-shadow;
        }

        &.active {
          border: 2px solid $bcg-green--lighter;
          background-color: $bcg-green--lighter;
        }
      }
    }
  }
}
