@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.list-header {
  width: 100%;
  @include flex-center-vert;
  justify-content: space-between;

  &__tabs {
    width: 100%;
    @include flex-center-vert;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    height: 100%;
    margin: 0;

    .item {
      padding-top: 10px !important;
      height: 100%;
      margin-left: 4px;
      text-align: center;
      min-width: 80px;
      cursor: pointer;
      border-bottom: 4px solid transparent;

      &:hover,
      &.active {
        border-bottom-color: $primaryColor;
      }

      @include respond-to(sm) {
        min-width: 60px;
      }
      @include respond-to(md) {
        min-width: 60px;
      }
    }
  }

  &__disabled {
    position: relative;
    color: $gray;
    cursor: not-allowed;
    z-index: -1;
  }

  &__filter-icon {
    margin-right: 12px;

    input + label:before,
    input + label:after {
      transform: scale(0.7) !important;
      margin-left: 10px !important;
    }

    &.disabled {
      cursor: default;
      color: $gray;
    }
  }
}
