@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.image-preview-container {
  @include flex-center-vert;
  text-align: center;
  position: relative;

  .placeholder {
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.preview-slider-container {
  height: 100%;
  width: 100%;
  padding: 0px 16px;

  .slick-list {
    width: calc(100% - 120px);
    height: 100%;
    margin: auto;
  }

  .slick-track,
  .slick-slide > div,
  .slider-block {
    height: 100%;
  }

  .slider-image-preview {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    padding: 6px 10px;
  }

  .custom-arrow {
    &--next {
      right: 24px;
    }
    &--prev {
      left: 24px;
    }
  }
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  padding: 6px 10px;
}

.no-preview-msg {
  @include flex-center;
  width: 100%;
  height: 100%;
  color: $gray--dark;
}
