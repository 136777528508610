.graph {
  &-desclaimers {
    position: absolute;
    width: 295px;
    bottom: 15px;
    right: 62%;
    display: flex;
    align-items: center;
    &-icon {
      width: 20px;
      margin-right: 5px;
    }
    &-txt {
      color: #919191;
      font-size: 0.75rem;
      line-height: 0.8rem;
    }
  }
}
