@import "../../styles/abstracts/variables";

.goto-slide {
  input {
    width: 48px !important;
    text-align: center !important;
    padding: 4px !important;
    margin-left: 8px !important;
    margin-right: 0px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
    border: 2px solid $primaryColor !important;
  }

  // override the semantic style with exact selector for higher preference
  .ui[class*="right labeled"].input > input {
    border-right-color: $primaryColor !important;
  }

  button {
    color: $brandWhite;
    font-weight: bold;
    padding: 0px 6px !important;
    border-left: 0px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background-color: $primaryColor !important;
  }
}
