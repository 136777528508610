@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.preview {
  background-color: $secondaryColor !important;
  overflow: hidden;

  @include respond-to(sm) {
    overflow-y: auto;
  }

  // Header CSS
  &__floating-header {
    @include flex-center-vert;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 24px;
    height: $pageHeader;
    z-index: 1;

    @include respond-to(sm) {
      height: calc(#{$pageHeader} - 10px);
    }
  }

  &__upper-container {
    padding: 48px 24px 8px 24px;
    height: 70vh;
    min-height: 250px;

    &--loader {
      @include flex-center;
      height: 100%;

      .ui.placeholder {
        margin: 0;
        width: 50vw;
        max-width: 50vw;
        min-width: 400px;
        height: 100%;
      }
    }

    &--thumbnail {
      position: relative;
      width: 100%;
      height: 100%;
    }

    @include respond-to(md) {
      height: 60vh;
    }
  }

  &__upper-slider {
    @include flex-center;
    height: 100%;
    min-width: 100%;
    padding: 0 64px;
    position: relative;
    outline: none;

    .slick-list {
      height: 100%;
    }

    &.slick-initialized .slick-slide {
      @include flex-center;
    }
  }

  &__slider-blocks {
    @include flex-center-vert;
    justify-content: space-between;

    &--slide {
      @include flex-column;
      align-items: flex-start;
      justify-content: center;
      width: 48vw;
      height: calc(70vh - 56px); // container padding

      span {
        height: 24px;
      }

      .slide-img {
        object-fit: scale-down;
        min-height: 170px;
        max-width: 100%;
        box-shadow: 0 0 5px 0 $gray;
      }

      @include respond-to(md) {
        width: 35vw !important;
        height: calc(60vh - 56px); // container padding
      }

      &.render-magically {
        width: 1px;
        height: 1px;
        opacity: 0;
      }
    }

    &.compare-view > &--slide {
      width: 42vw;
      height: 65vh;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }

      @include respond-to(md) {
        height: 50vh;
      }
    }
  }

  &__upper-arrow {
    position: absolute;
    top: 45%;
    width: 32px;
    z-index: 2 !important;

    img {
      height: 32px;
      cursor: pointer;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  // Lower Loader
  &__lower-slide-container {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
    width: 100%;

    &--loader {
      @include flex-center-vert;
      justify-content: space-around;

      .ui.placeholder {
        margin: 0 !important;
        height: calc(100% - 30px);
        width: calc(85% / 7);

        .square.image:not(.header) {
          padding-top: calc(100% - 50px);
        }
      }
    }

    @include respond-to(sm) {
      position: relative;
    }
  }

  &__lower-slider {
    @include flex-center;
    height: 100%;

    &__arrows {
      max-width: 32px;
      cursor: pointer;
      i {
        color: $primaryColor;
      }

      &--disabled {
        img {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
    }

    &__blocks {
      width: calc((100% - 64px));
      @include flex-center;
    }

    &__block {
      @include flex-center;
      flex-direction: column;
      width: calc(100% / 7);

      &--slide-no {
        margin-bottom: 4px;
      }

      &--thumbnail {
        cursor: pointer;
        border: 4px solid transparent;
        width: 90%;
      }

      &--active {
        border: 4px solid $bcg-green--light;
      }

      &--status {
        @include font-size(14, 14);
        margin-top: 8px;
        max-height: 16px;
      }
    }
  }

  &__goto-slide-form {
    z-index: 2;
  }

  &__goto-slide {
    @include flex-center-vert;
    justify-content: flex-end;
    margin: 0 28px;
    @include font-size(16, 14);
  }
}
