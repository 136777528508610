@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.summary {
  .ui.tiny.modal {
    width: 600px !important;
  }

  .bold {
    font-weight: bold;
  }

  &__header {
    @include flex-center;
    margin-bottom: 16px;

    .header {
      color: $primaryColor;
    }

    img {
      position: absolute;
      right: 16px;
      top: 16px;
      height: 12px;
      cursor: pointer;
    }
  }

  &__close-icon {
    &--disabled {
      cursor: not-allowed !important;
    }
  }

  &__head-desc-row {
    margin-left: 14px;
  }

  &__head-desc {
    background-color: $gray--lighter;
    border-radius: 4px;
    padding: 4px 0;
  }

  &__notes {
    @include font-size(14, 14);
    color: $notify-warn-highlight;
    margin-top: 24px;

    p {
      padding: 0;
      margin: 0;
      margin-top: 4px;
      @include font-size(14, 14);
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;

    button {
      height: 40px;
      width: 120px;
      margin-left: 8px !important;
    }
  }

  &__footer {
    th {
      font-weight: 800 !important;
    }
    tr {
      height: 44px;
    }
  }

  &__download-btn {
    span {
      color: $brandWhite !important;
      @include font-size(16, 16);
    }
  }
}

.summary-margin {
  margin-top: 10% !important;
}
