@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.suggestion-header {
  @include flex-center-vert;
  justify-content: space-between;
  &__arrow {
    margin: 0px !important;
    padding: 8px !important;
    img {
      width: 12px;
      height: 16px;
    }
  }
}
.smart-suggestion {
  &__container {
    position: relative;
    @include flex-center-column;

    .reset-manual-edit {
      @include flex-center-column;
      color: $gray--dark;
      text-align: center;

      span {
        color: $primaryColor;
        cursor: pointer;
      }
    }
  }
}
