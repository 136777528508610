@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.user-guide-floating-icon {
  @include flex-center;
  position: fixed;
  top: 15%;
  left: 0;
  width: 35px;
  background: $bcg-green--blue-green;
  border: 3px solid white;
  color: white;
  line-height: 16px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  writing-mode: vertical-rl;
  padding: 15px 0;
  box-shadow: 3px 0px 2px 0px #9b9b9b;

  &:hover,
  &:focus,
  &:active {
    color: white !important;
    box-shadow: 3px 0px 2px 0px #9b9b9b;
  }
}
