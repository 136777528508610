@import "../../../../styles/abstracts/variables";

.confirmation-modal {
  &.ui.modal {
    > .header {
      position: relative;
      border-bottom: none;
      background-color: $warningYellow;

      .close-btn {
        position: absolute;
        right: 1.5rem;
        top: 1.75rem;
      }
    }

    > .content {
      background-color: $warningYellow;
    }

    > .actions {
      border-top: none;
      background-color: $warningYellow;

      .button {
        min-width: 120px !important;
      }
    }
  }
}
