@import "../../styles/abstracts/variables";

.user-profile-image {
  height: 46px;
  width: 46px;
  object-fit: cover;
  border: 2px solid $brandWhite;
  cursor: pointer;
  &:hover {
    border-color: $primaryColor;
  }
}
