@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.slider {
  position: relative;

  &__ppt-name {
    text-align: center;
    margin-bottom: 0 !important;
    position: relative;
    left: 50%;
    height: $carouselHeaderHeight;
    transform: translateX(-50%);
  }

  &__progress-bar {
    position: absolute;
    right: 0px;
    bottom: 2px;
    z-index: 1;

    p {
      margin: 0;
      padding: 0 0 10px 10px;
    }
  }

  &__progress-bar-title {
    font-weight: normal;
    margin: inherit 10px;
  }

  &__preview-slide {
    position: absolute;
    bottom: 10px;
    right: 0px;
    padding: 5px !important;
  }

  &__goto-slide {
    display: inline;
  }
}

// Override element width when loading loader from slider
.loader-container {
  padding: 6px 0px;
  .slick-slide {
    .slider-block {
      width: 420px !important;
      height: $sliderImageLoaderHeight;
    }

    &[data-index="1"] {
      transform: scale(1) !important;
    }
  }
}
