@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.home {
  position: relative; // For disclaimer and version component
  height: 100vh;

  .ui.container {
    position: relative;
  }

  .ui.grid {
    padding-top: 15vh;
    width: 100%;
  }

  &__container {
    min-height: calc(100vh - 20px);
    @include flex-center-column;
    justify-content: space-between;
  }

  &__divider {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    hr {
      border: 0;
      width: 2px;
      border-left: 2px solid #c8c8c8;
      margin-top: 35px;
      height: 360px;
    }

    @include respond-to(sm) {
      display: none;
    }
  }
}
