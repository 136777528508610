@import "../../styles//abstracts/variables";
@import "../../styles//abstracts/mixins";

.image-manual-editor {
  overflow: hidden !important;

  &__header {
    position: absolute;
    top: 8px;
    right: 0;
    width: 625px;

    h2 {
      @include flex-center;
      margin-bottom: 5px;
      height: 43px;
      color: $primaryColor !important;
    }

    .ui.divider {
      margin: 0;
    }

    .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      img {
        width: 12px;
        height: 10px;
      }
    }
  }

  &__menu-container {
    padding-right: 0 !important;
    background-color: $primaryColor;
  }

  &__menu {
    .menu-item {
      @include flex-center-vert;
      justify-content: flex-start;
      cursor: pointer;
      height: 54px;
      color: $white;
      padding-left: 16px;
      text-transform: capitalize;

      &.menu-selected,
      &:hover {
        background-color: $gray--light;
        color: $brandBlack;
        .icon-fuzzify {
          background: url("../../assets/images/blur.svg") no-repeat;
          background-size: contain;
          background-position: center;
        }
        .icon-stamp {
          background: url("../../assets/images/stamp.svg") no-repeat;
          background-size: contain;
          background-position: center;
        }
      }

      &--icon {
        width: 20px;
        height: 18px;
        padding-right: 4px;
      }

      .icon-fuzzify {
        background: url("../../assets/images/blur-white.svg") no-repeat;
        background-size: contain;
        background-position: center;
      }

      .icon-stamp {
        background: url("../../assets/images/stamp-white.svg") no-repeat;
        background-size: contain;
        background-position: center;
      }

      .icon-text {
        @include font-size(20, 20);
      }
    }
  }

  &__submenu {
    padding-left: 0 !important;
    background-color: $gray--light;
  }

  &__select {
    margin: 16px 0 0 12px;

    .select-area-container {
      @include flex-center;
      border: 1px dashed $gray;
      color: $primaryColor;
      transform: scale(1);
      transition: all 0.3s;

      img {
        margin-right: 8px;
        height: 24px;
      }

      &:hover,
      &--selected {
        border-color: $primaryColor;
        background: $brandWhite;
      }

      &--selected {
        transform: scale(0.97);
      }
    }
  }

  &__stamp {
    @include flex-center-column;

    .stamp-item {
      margin-top: 32px;
      cursor: pointer;
      padding: 6px;
      @include rounded;

      p {
        margin-top: 4px;
        text-align: center;
      }

      &:hover {
        -webkit-box-shadow: 0 0 10px $gray--darker;
        box-shadow: 0 0 10px $gray--darker;
      }
    }

    img {
      height: 56px !important;
      width: 80px !important;
    }
  }

  &__text {
    padding: 16px 0 0 12px;

    .patch-with-icon {
      @include font-size(16, 16);
      color: $primaryColor;
      width: 100%;
      margin-bottom: 20px;
    }

    .shape-switch {
      @include flex-center;
      &--rect,
      &--circle {
        width: 50%;
        border: 1px solid $primaryColor;
        @include flex-center;
        height: 30px;
        cursor: pointer;
        &:hover,
        &--selected {
          background-color: $primaryColor;
          box-shadow: inset 0 8px inset $gray;
          span {
            border-color: $brandWhite;
          }
        }
      }

      &--rect {
        border-right-width: 0px;
        border-radius: 5px 0 0 5px;
      }

      &--circle {
        border-radius: 0 5px 5px 0;
      }

      .rect-icon {
        height: 18px;
        width: 18px;
        border: 2px dashed $primaryColor;
      }
      .circle-icon {
        height: 18px;
        width: 18px;
        border: 2px dashed $primaryColor;
        border-radius: 50%;
      }
    }

    .add-text {
      @include font-size(18, 16);
      margin: 1.5em 0;
      cursor: pointer;
      span {
        padding-left: 4px;
        text-decoration: underline;
      }
    }

    .text-item {
      @include flex-center-vert;
    }

    .text-item-span {
      padding: 8px 13px;
      border: 1px solid $primaryColor;
      color: $primaryColor;
      @include font-size(18, 16);
      &:hover {
        background-color: $primaryColor;
        color: $brandWhite;
      }
    }

    .text-item-span--selected {
      color: $brandWhite;
      background-color: $primaryColor;
    }

    .text-item-bold {
      border-radius: 5px 0 0 5px;
    }

    .text-item-underline {
      border-radius: 0 5px 5px 0;
    }

    .text-item-italic {
      border-width: 1px 0;
    }

    .color-picker {
      margin-top: 20px;
      border: none !important;
      padding: 0 !important;
    }
  }

  &__crop {
    padding: 16px;

    .crop-item {
      margin-top: 16px;
    }

    .crop-action {
      button {
        width: 140px;
        margin-top: 16px;
      }
    }
  }

  &__main-menu {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    li {
      cursor: pointer;
      color: white;
      padding: 10px;
      height: 14.28%;
    }
  }

  &__secondary-actions {
    position: absolute;
    bottom: 24px;
    left: 300px;
    color: $primaryColor;

    span {
      margin-left: 16px;
      &:hover {
        color: $primaryColor;
      }
    }
  }

  &__action-container {
    @include flex-center-vert;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px;

    button {
      @include flex-center;
      margin-left: 8px !important;
      height: 32px;
      width: 88px;
      padding: 0 !important;
    }
  }

  .tui-image-editor-container {
    background-color: white !important;
    height: 500px !important;
    margin-top: -20px;
  }

  .tui-image-editor {
    top: 0 !important;
    border: 1px solid #cccccc !important;
  }
}
