@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.table-me-header {
  width: 100%;
  @include flex-center-vert;

  &__go-back {
    cursor: pointer;
    color: $primaryColor;
    max-width: 64px;
  }

  &__column-header {
    @include flex-center-vert;
    width: calc(100% - 70px);
  }

  &__column-title {
    @include truncate-ellipsis;
    width: 100%;
  }

  &__column-arrows {
    display: inline-flex;
    cursor: pointer;
    color: $primaryColor;
    @include font-size(20, 16);

    .disabled {
      color: $gray--dark;
    }
  }
}
