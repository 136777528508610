@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.upload-files {
  width: 100%;
  height: auto;

  .placeholder {
    height: $uploadContainer;
  }

  @include respond-to(sm) {
    margin-bottom: 20px !important;
  }

  .ui.button {
    padding-left: 46px;
    padding-right: 46px;
  }

  &__upload-container-loader {
    max-width: 100% !important;
    height: 100%;
    margin-top: 20px;
    @include rounded;
  }

  &__upload-container {
    margin-top: 20px;
    background-color: $white;
    padding: 24px;
    border: 1px dashed $gray--dark;
    width: 100%;
    height: $uploadContainer;
    @include rounded;
    cursor: pointer;
    &:hover {
      border-color: $gray--darker;
      @include box-shadow;
    }
  }

  &__upload-section {
    background-color: $bcg-green--lightest;
    height: 100%;
    @include flex-center-column;
    opacity: 0.9;
    color: $bcg-green;

    &:hover {
      color: $brandBlack;
      opacity: 1;
    }
  }

  &__additional-messages {
    position: relative;
    margin-left: 8px;
  }

  &__ppt-icon {
    height: 64px;
  }

  &__label {
    @include font-size(16, 16);
  }

  &__drag-active {
    background-color: $activeGreen;
    border: 3px dashed $gray--dark;
    @include font-size(20, 20);
  }

  &__error-msg {
    @include flex-center-horiz;
  }
}
