@import "../../styles/abstracts/variables";

.text-area {
  padding: 8px;
  padding-right: 4px;
  width: 58%;

  .grouped-paragraph {
    margin: 8px 0;
    padding: 4px;
    border: 0px solid $brandWhite;
    border-left-width: 3px;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .updated-text {
      color: $primaryColor;
    }
  }

  .manually-edited {
    color: $primaryColor;
  }
}

.text-area-selector {
  height: 100%;
  color: $gray--darker;
  margin: 0;
  overflow: auto;
  padding-right: 10px;
  white-space: pre-wrap;
  word-break: break-word;

  &--identified {
    cursor: pointer;
  }

  &--visited {
    color: $primaryColor;
  }

  &--not-visited {
    color: $bcg-red;
  }

  &--current-visited {
    color: $primaryColor;
    background-color: $yellow-highlight;
    &:hover {
      background-color: $yellow-highlight--hover !important;
    }
  }

  &--current-not-visited {
    color: $bcg-red;
    background-color: $yellow-highlight;
    &:hover {
      background-color: $yellow-highlight--hover !important;
    }
  }

  &--identified:hover,
  .current-hover {
    background-color: $yellow-highlight;
  }

  &--manual-edit {
    *,
    *:hover {
      color: $primaryColor;
      background-color: transparent !important;
    }
  }
}

.text-area {
  .grouped-paragraph:hover,
  .active-group {
    border-color: $blue-highlight;
  }
}
