// ----------- COLORS  ---------------//
// Primary brand colors
$bcg-green--dark: #00532f;
$bcg-green: #177b57;
$bcg-green--blue-green: #3ead93;
$bcg-green--blue: #3fad93;
$bcg-green--light: #32c77f;
$bcg-green--lighter: #c9e7ca;
$bcg-green--lightest: #f0f9f1;
$bcg-yellow: #e4ef39;
$bcg-red: #d50000;
$bcg-blue: #00bcd4;
$bcg-blue--light: #88c0fe;
$bcg-blue--dark: #1e455e;
$bcg-orange: #ff7002;

// Grayscale
$black: #000000;
$black--secondary: #111111;
$gray--darkest: #333333;
$gray--darker: #707070;
$gray--dark: #b7b7b7;
$gray: #d9d9d9;
$gray--light: #f2f2f2;
$gray--lighter: #fafbfb;
$gray--lightest: #fafafa;

$white: #ffffff;
$transparent: rgba(0, 0, 0, 0);

//notifications
$notify-error: #fae6e6;
$notify-error-highlight: $bcg-red;
$notify-info: #eaf7f9;
$notify-info-highlight: $bcg-blue;
$notify-success: #edf5d7;
$notify-success-highlight: #4caf50;
$notify-warn: #fff6d0;
$notify-warn-highlight: #ff9800;

// CUSTOM COLOR CODE MAPPING

$yellow-highlight: #eee89a;
$yellow-highlight--hover: #f9f079;
$blue-highlight: #0c61fe;

// Warning Modal Backgroud Color
$warningYellow: #fbf9e6;

// Drag Files active color
$activeGreen: #cceacf;

// BASIC BRAND COLOR REMAPPING
$brandWhite: $white;
$brandBlack: $black--secondary;

$primaryColor: $bcg-green--blue-green;
$secondaryColor: $gray--light;

// ----------- SPACING ---------------//

// Spacing - As per bootstrap rule
$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x * 0.25,
    y: $spacer-y * 0.25
  ),
  2: (
    x: (
      $spacer-x * 0.5
    ),
    y: (
      $spacer-y * 0.5
    )
  ),
  3: (
    x: (
      $spacer-x
    ),
    y: (
      $spacer-y
    )
  ),
  4: (
    x: (
      $spacer-x * 1.5
    ),
    y: (
      $spacer-y * 1.5
    )
  ),
  5: (
    x: (
      $spacer-x * 3
    ),
    y: (
      $spacer-y * 3
    )
  )
);

// ----------- RESPONSIVE BREAK POINTS ---------------//

// Extra small screen / phone
// $screen-xs: 480px;
$screen-xs: 1; // We are not supporting extra small screen

// Small screen / tablet
$screen-sm: 768px;

// Medium screen / desktop
$screen-md: 992px;

// Large screen / wide desktop
$screen-lg: 1200px;

// So media queries don't overlap when required, provide a maximum
// $screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);

// ----------- INDIVIDUAL PAGE LAYOUT VARIABLES  ---------------//

// Home page
$uploadContainer: 356px;

// Santizing window layout
$pageHeader: 58px;
$carouselHeight: 52vh;
$carouselHeaderHeight: 16px;
$sliderImageHeight: calc(#{$carouselHeight} - #{$carouselHeaderHeight});
$sliderImageLoaderHeight: calc(
  #{$carouselHeight} - #{$carouselHeaderHeight} - 16px
);
$editorSectionHeight: calc(
  100vh - #{$pageHeader} - #{$carouselHeight} + 8px
); // margin top -8px on the slider

// Smart section
$suggestionHeaderHeight: 0px;
$suggestionFooterHeight: 28px;
$suggestionContainerHeight: calc(
  100% - #{$suggestionHeaderHeight} - #{$suggestionFooterHeight}
);
