// Contains all the mixins used inside the project are

// --------- Margin and Padding helper classes ---------- //
@mixin spacing() {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x: map-get($lengths, x);
      $length-y: map-get($lengths, y);

      .#{$abbrev}-#{$size} {
        #{$prop}: $length-y $length-x !important;
      }
      .#{$abbrev}x-#{$size} {
        #{$prop}-left: $length-x !important;
        #{$prop}-right: $length-x !important;
      }
      .#{$abbrev}y-#{$size} {
        #{$prop}-top: $length-y !important;
        #{$prop}-bottom: $length-y !important;
      }
      .#{$abbrev}t-#{$size} {
        #{$prop}-top: $length-y !important;
      }
      .#{$abbrev}r-#{$size} {
        #{$prop}-right: $length-x !important;
      }
      .#{$abbrev}b-#{$size} {
        #{$prop}-bottom: $length-y !important;
      }
      .#{$abbrev}l-#{$size} {
        #{$prop}-left: $length-x !important;
      }
    }
  }
}

// --------- FONTS ---------- //

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: $font-size * 1px;
  font-size: ($font-size * 0.0625) + rem;

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: calc($line-height / $font-size);
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{calc($letter-spacing / $font-size)}em;
  }
}

// Font face
@mixin font-face(
  $font-name,
  $pathToFolder,
  $fileName,
  $weight: normal,
  $style: normal
) {
  @font-face {
    font-family: quote($font-name);
    src: url($pathToFolder + "EOT/" + $fileName + ".eot");
    src: url($pathToFolder + "EOT/" + $fileName + ".eot?#iefix")
        format("embedded-opentype"),
      url($pathToFolder + "WOFF/" + $fileName + ".woff") format("woff"),
      url($pathToFolder + "WOFF2/" + $fileName + ".woff2") format("woff2"),
      url($pathToFolder + "TTF/" + $fileName + ".ttf") format("truetype"),
      url($pathToFolder + "SVG/" + $fileName + ".svg") format("svg");
    font-weight: $weight;
    font-style: $style;
  }
}

// --------- FLEX ---------- //

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex !important;
  align-items: center;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

// --------- respond-to() ---------- //

@mixin respond-to($media) {
  // @if $media == xs {
  //   @media (max-width: $screen-xs-max) {
  //     @content;
  //   }
  // } @else
  @if $media == sm {
    @media (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
}

// ------------ GENERIC ----------- //

@mixin rounded {
  border-radius: 7px !important;
}

@mixin box-shadow {
  box-shadow: 0px 0px 4px 0px $gray, 0px 1px 2px -2px $gray,
    0px 1px 4px 0px $gray;
}

@mixin hover-shadow {
  box-shadow: 0px 0px 4px 0px $gray--dark, 0px 3px 4px -2px $gray--dark,
    0px 2px 6px 0px $gray--dark;
}

@mixin warning-icon {
  content: "⚠";
  font-weight: normal;
  padding: 2px 4px;
  border-radius: 50%;
  background-color: $notify-warn;
  color: $notify-warn-highlight;
  margin: 0px 6px 0 3px;
}

@mixin error-icon {
  content: "!";
  font-weight: normal;
  padding: 2px 4px;
  border-radius: 50%;
  background-color: $notify-error;
  color: $notify-error-highlight;
  margin: 0px 6px 0 3px;
}

@mixin success-icon {
  content: "\2713";
  font-weight: normal;
  padding: 2px 4px;
  border-radius: 50%;
  background-color: $notify-success;
  color: $notify-success-highlight;
  margin: 0px 6px 0 3px;
}

@mixin truncate-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
