// contains overriding styles of vendor styles

// Semantic UI
.ui {
  // header styles
  &.header {
    font-family: "Henderson BCG Sans";
  }

  &.input {
    > input {
      border-radius: 7px;
      border: 1px solid $gray;
      &:focus,
      &:hover,
      &:active {
        border-color: $gray--dark;
      }
    }
  }

  // button styles
  &.button {
    font-family: "Henderson BCG Sans";
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: normal;

    &.primary {
      background-color: $primaryColor;
      color: $white;

      &:hover:not(.hover),
      &:active:not(.hover) {
        background-color: $primaryColor;
      }

      &:focus {
        background-color: $primaryColor;
      }

      &.disabled {
        background-color: $gray--dark;
        color: $brandBlack;
      }

      &.hover {
        &:hover,
        &:active,
        &:focus {
          background-color: $bcg-green;
        }
      }
    }

    &.secondary {
      background-color: transparent;
      color: $primaryColor;
      border: 2px solid $primaryColor;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: $primaryColor; // litle dark than normal
        border-color: $bcg-green;
      }

      &.disabled {
        background-color: $white;
        color: $gray--dark;
        border-color: $gray;
      }
    }

    // &:focus {
    //   box-shadow: 0 0 4px $bcg-green--light !important;
    // }
  }

  // tooltip styles
  &.popup {
    &::before {
      // Using important as there are styles based on the position (which takes more precedence)
      background-color: $gray--darkest !important;
      border: none !important;
      box-shadow: none !important;
    }
    color: $white;
    background-color: $gray--darkest;
    border: none;
    padding: 8px;
    font-weight: bold;
    @include font-size(14, 16);

    &.warning {
      &::before {
        background-color: $gray--darkest !important;
      }

      background-color: $gray--darkest;
    }
  }

  &.card {
    border-radius: 4px;
    box-shadow: 0 0 4px 0 $gray;
  }

  &.modal {
    border-radius: 4px;
  }

  &.divider:not(.vertical):not(.horizontal) {
    border-top: 2px solid rgba(34, 36, 38, 0.15) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  &.checkbox {
    label,
    & + label {
      color: $brandBlack !important;
    }

    input {
      ~ label:before {
        border-color: $primaryColor !important;
        border-radius: 0;
      }

      &:checked {
        ~ label:before {
          background-color: $primaryColor !important;
        }

        ~ label:after {
          color: $brandWhite !important;
          @include font-size(14);
          font-weight: normal;
          top: 2px !important;
        }
      }

      &:indeterminate ~ label:after {
        color: $brandWhite !important;
        background: $primaryColor !important;
      }
    }

    &.with-shadow input:checked ~ label:before {
      box-shadow: 0px 0px 12px 0px $gray;
    }

    &.radio {
      .box:before,
      label:before {
        width: 15px;
        height: 15px;
      }

      input:focus ~ .box:before,
      input:focus ~ label:before,
      input ~ .box:before,
      input ~ label:before {
        background-color: $brandWhite !important;
        border-color: $gray !important;
      }

      input:focus:checked ~ .box:after,
      input:focus:checked ~ label:after,
      input:checked ~ .box:after,
      input:checked ~ label:after {
        background-color: $primaryColor;
        top: 1px !important;
      }
    }

    &.toggle {
      input {
        &:checked,
        &:focus:checked {
          ~ label:before {
            background-color: $primaryColor !important;
          }

          ~ label:after {
            top: 0 !important;
            left: 1.8rem !important;
          }
        }
        ~ label:before,
        &:focus ~ label:before {
          background-color: $gray--dark !important;
        }
      }
    }
  }

  &.dropdown {
    .menu {
      border: 1px solid $primaryColor;
      & > .item {
        color: $primaryColor !important;
      }
    }
    &.floating > .menu {
      margin-top: 2px !important;
      border-radius: 0 !important;
      .active.item {
        font-weight: normal !important;
      }
    }
  }

  &.progress.success .bar {
    background-color: $primaryColor !important;
  }
}

// Slick slider styles

.slick-slider {
  .custom-arrow {
    width: 18px;
    height: 28px;
    &--next {
      right: 0;
      &::before {
        content: " ";
      }
    }

    &--prev {
      left: 0;
      z-index: 1;
      &::before {
        content: " ";
      }
    }

    &.slick-disabled {
      opacity: 0.25;
      cursor: not-allowed;
    }
  }
}

.semantic_ui_range_inner {
  div:nth-child(1) {
    background-color: $brandBlack;
  }

  div:nth-child(2) {
    background-color: $primaryColor;
  }

  div:nth-child(3) {
    border: 1px solid $primaryColor;
  }
}

// ----------------- CUSTOM TOASTER --------- //
.custom-toaster {
  .Toastify__toast {
    color: $brandBlack;
    background: $brandWhite;
    position: relative;
  }

  .Toastify__close-button {
    color: $brandBlack;
  }

  .Toastify__toast--default {
    background: $brandWhite;
    color: $brandBlack;
  }

  .Toastify__toast--error,
  .Toastify__toast--success,
  .Toastify__toast--warning {
    .Toastify__toast-body {
      margin-left: 38px; // 32px is the size of the toast icon
    }

    &:before {
      @include flex-center;
      @include font-size(24);
      line-height: 0;
      width: 32px;
      height: 32px;
      padding: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .Toastify__toast--info {
    background: $brandWhite;
  }

  .Toastify__toast--success {
    color: $notify-success-highlight;
    &:before {
      @include success-icon;
    }
  }

  .Toastify__toast--warning {
    color: $notify-warn-highlight;
    &:before {
      @include warning-icon;
    }
  }

  .Toastify__toast--error {
    color: $notify-error-highlight;
    &:before {
      @include error-icon;
    }
  }
}

// Dropdown
.ui.dropdown .scrolling.menu,
.ui.scrolling.dropdown .menu {
  max-height: 144px;
}

// Usabilla button
.usabilla_live_button_container {
  top: 48% !important;
}
