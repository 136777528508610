@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.editor-section {
  clear: both;
  height: $editorSectionHeight;
  min-height: 182px;
  width: 100%;
  box-shadow: 0 -2px 10px 0 $gray;
  @include flex-center-vert;
  justify-content: space-between;
  position: relative;
  overflow: auto;
  transition: width 0.3s ease;

  .header-stripe {
    height: 40px;
    font-weight: 600;
  }

  .main-block {
    width: 100%;
    height: calc(100% - 48px);
    background-color: $brandWhite;
    box-shadow: 0 0px 4px 0 $gray;
    transition: all 0.25s ease 0s;
    @include rounded;
    overflow: hidden;

    &:hover,
    &:active {
      @include hover-shadow;
    }

    &.no-result {
      @include flex-center;
      color: $gray--dark;
      text-align: center;
    }
  }

  &__list {
    height: 100%;
    width: 38%;
    min-width: 360px;
    margin-left: 8px;
  }

  .list-container {
    width: 100%;
  }

  &__suggestion {
    width: 22%;
    min-width: 220px;
    height: 100%;
    margin: 0px 8px;
  }

  &__editor {
    width: 38%;
    min-width: 300px;
    height: 100%;
    margin-right: 8px;
  }

  .editor-header {
    @include flex-center-vert;
    justify-content: space-between;

    &__title {
      @include flex-center-vert;
      padding: 0 12px;
      &.manual-edit {
        padding: 0 16px;
      }
    }

    &__actions {
      @include flex-center-vert;
    }

    &__manual-edit {
      color: $primaryColor;
      font-weight: 400;

      img {
        display: inline;
        vertical-align: middle;
        padding-bottom: 2px;
        width: 12px;
        height: 16px;
      }
    }
  }
}

// If manually edited, hide smart suggestion section and apply css style to all the children
.editor-section.half-width {
  .editor-section__list,
  .editor-section__editor {
    width: 50%;
    margin-right: 8px;
  }
}

// List view is common for image, graph and tables

.image-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0 6px;

  &__item {
    position: relative;
    margin: 2px;
    width: 120px;
    height: 80px;
    border: 3px solid $gray;
    cursor: pointer;

    &:hover:not(.cursor-not-allowed),
    &--current {
      border-color: $primaryColor;
      @include hover-shadow;
    }

    &--current-preview {
      border-color: $primaryColor;
      @include hover-shadow;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  &__checkbox {
    position: absolute;
    top: 1px;
    right: 1px;
  }

  &__badge {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 2px 2px 2px 0px;
    text-align: center;
    color: $brandWhite;
    background-color: $primaryColor;
    @include font-size(14, 16);
  }

  &.image-list .overlay-loader > img {
    width: 18px;
    height: 18px;
  }
}

.graph-list {
  .image-list__item {
    width: 220px;
    height: 140px;
    margin: 0.75rem;
  }
}
