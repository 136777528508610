@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.user-guide {
  display: flex;

  &__left-section {
    @include flex-center;
    height: 100vh;
    width: 25vw;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $bcg-green--lightest;
    box-shadow: 0 0 0.5rem $gray--dark;
  }

  &__description-container {
    padding: 16px;
    margin-left: 16px;
    max-height: 75%;
    overflow-y: auto;
    border-left: 4px solid $bcg-green;

    h1 {
      color: $bcg-green !important;
    }
  }

  &__right-section {
    height: 100vh;
    width: 100vw;
    padding-left: 25vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray;
  }

  &__slider {
    width: 73vw;

    &--image {
      object-fit: contain;
    }
  }

  &__actions {
    position: fixed;
    bottom: 5%;

    span {
      color: $bcg-green--blue-green;
      @include font-size(18, 18);
    }
  }

  &__prev-btn {
    padding-right: 16px !important;
  }

  &__next-btn {
    padding-left: 16px !important;
  }

  &__skip-guide {
    position: fixed;
    top: 16px;
    right: 16px;
    opacity: 0.7;
    cursor: pointer;
  }
}
