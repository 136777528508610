@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.file-list {
  @include flex-column;
  align-items: flex-start;

  .ui.card {
    width: 536px;
    height: 112px;
    padding: 24px;
    border: 3px solid $white;
  }

  &__card-container {
    width: 100%;
    height: 62vh;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 18px;
  }

  &__actions {
    width: 100%;
    margin-top: 32px !important;
    @include flex-center-vert;
    justify-content: space-between;
    padding-right: 18px;
  }

  .ui.button {
    width: 200px;
  }

  &__card-content {
    width: 100% !important;
    min-height: 112px;
    padding: 12px 12px 8px 12px;
    background-color: $brandWhite;
    border: 3px solid $brandWhite !important;
    margin: 0;
    margin-top: 16px;
    @include flex-center-horiz;
    flex-direction: column;
    @include rounded;

    &.showing-progree-bar {
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      .file-list__trash-icon {
        visibility: visible;
      }
    }
  }

  &__foot-note {
    margin-top: 4px;
    margin-left: 8px;
  }

  &__card-content-elements {
    @include flex-center-vert;
    justify-content: space-between;
    flex-direction: row !important;
  }

  &__ppt-icon {
    background-color: $bcg-green--lightest;
    height: 64px;
    width: 64px;
    margin-right: 8px;
    border-radius: 50%;
    @include flex-center;

    img {
      height: 28px;
    }
  }

  &__name-container {
    width: calc(70% - 64px - 20px - 24px);
  }

  &__status {
    @include flex-center-vert;
    justify-content: flex-start;
    width: 30%;
    padding-right: 10px;

    img,
    .icon {
      margin-right: 8px;
    }

    .icon.check,
    .icon.warning {
      margin-top: -4px !important;
    }

    &--completed {
      color: $primaryColor;
    }

    &--failed {
      color: $bcg-red;
    }
  }

  &__trash-icon,
  &__trash-placeholder {
    height: 20px;
    width: 20px;
    visibility: hidden;
  }

  &__trash-icon {
    opacity: 0.7;
    background: url("../../assets/images/delete-active.svg") no-repeat;
    background-size: contain;
    cursor: pointer;

    &--selected,
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &__highlighted-card {
    border-color: $primaryColor !important;
  }
}

@keyframes backgroundColorFade {
  0% {
    background-color: $bcg-green--lightest;
  }
}
