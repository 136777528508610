.custom-action-button {
  position: relative;
  &__loader {
    position: absolute !important;
    left: 0px;

    &.no-icon {
      left: -14px;
    }
  }

  &__btn-with-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    line-height: 0.8em;
  }
}
