@import "../../styles/abstracts/variables";

.text-preview-container {
  padding: 12px;
  padding-right: 4px;

  &__loader {
    padding: 0px;
  }

  &__preview {
    height: 100%;
    padding-right: 10px;
    overflow: auto;
    color: $gray--darker;

    .current-text {
      background-color: $yellow-highlight;
    }
  }
}
