@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.header-buttons {
  height: $pageHeader;
  @include flex-center-vert;

  .header {
    width: 38%;
    min-height: 20px;
    @include truncate-ellipsis;
    text-align: center;
  }

  &__actions {
    position: absolute;
    right: 80px;
    display: flex;
    height: 34px;

    &--home {
      padding: 0.65em 0.85em !important;

      .icon {
        @include font-size(18, 16);
        opacity: 1 !important;
      }
    }

    &--preview {
      margin: 0 1em !important;

      &.button {
        @include font-size(15, 12);
      }
    }

    &--menu {
      padding: 0.75em 1em !important;

      .menu .text {
        padding-left: 0.5rem;
        @include font-size(15, 12);
      }

      .icon {
        margin: 0 !important;
      }
    }
  }
}
